.win-copy {
  position: relative;
  z-index: 2;
}

.win-container {
  position: relative;
  top: -30px;
  transform: rotate(-1deg);
}

.win-img {
  width: 105%;
}

.win-prize-copy {
  background-image: url('../../assets/voucher-bg-tile@1x.png');
  height: 66px;
  width: 100%;
}

.win-prize-copy  p{ 
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  text-align: center;
}

.win-prize-copy__header {
  color: #FFFFFF;
  padding-top: 15px;
}

.win-prize-copy__description {
  color:  #f0d695;
}

.win-action-text {
  padding-bottom: 16px;
}

.win-action-terms-conditions-text {
  padding-bottom: 24px;
}

.win-code-coupon {
  display: inline-block;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;

  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #FFFFFF;

  padding: 8px 16px;
  transition: background 500ms;
  margin-bottom: 16px;
}

.win-code-coupon svg {
  margin-left: 15px;
}

.copied {
  background-color: white;
  color: black;
}

.copied svg{
  stroke: black;
}


@media only screen and (max-width: 320px) {
  /* For mobile phones: */
  .win-copy {
    width: 80%;
  }

  .win-prize-copy {
    font-size: 12px;
  }

  .win-code-coupon  {
    margin-bottom: 10px;
    margin-top: -15px;
  }

  .win-action-terms-conditions-text {
    padding-bottom: 10px;
  }

  .win-action-text {
    padding-bottom: 8px;
  }

  .win-code-coupon {
    font-size: 18px;
    padding: 4px 8px;
  }
}