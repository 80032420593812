.btn {
    background: white;
    border: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #583E2A;

    padding: 16px 32px;
    box-sizing: border-box;
    border-radius: 3px;
}