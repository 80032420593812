.not-supported-message {
  font-size: 20px;
  font-family: 'Roboto Mono';
  color: #f0d695;
  padding: 60px 20px 70px;
}

canvas {
  height: 200px;
  width: 200px;
}