/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  overflow-x: hidden;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  background-image: url('./assets/background@3x.jpg');
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.mcxsp-logo {
  padding: 15px 0 1rem;
}

.action-image {
  max-width: 375px;
  padding-bottom: 1rem;
  width: 100%;
}

.description-text {
  color: #FFFFFF;
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(-1deg);
  padding: 0 20px 25px;
}

.content-container {
  overflow: hidden;
}

.description-text__highlight {
  color:  #f0d695;
}

.scrath-canvas {
  padding-bottom: 27px;
  transform: rotate(-1deg);
}

.action-text {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  padding-bottom: 7px;
}

.terms-conditions-text {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Roboto';
  font-size: 9px;
  line-height: 120%;
  text-align: center;
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  transform: scale(2);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 150ms, transform 150ms;
}

@media only screen and (max-width: 320px) {
  /* For mobile phones: */

  .mcxsp-logo {
    padding: 10px 0 5px;
    width: 20%;
  }

  .action-image {
    width: 80%;
    padding-bottom: 4px;
  }

  .action-text {
    font-size: 10px;
    padding-bottom: 4px;
  }

  .description-text {
    font-size: 0.7rem;
    padding: 0 20px 15px;
  }
}